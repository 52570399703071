import Debug from 'debug';
import mitt from 'mitt';

import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';
import ApiError from './errors/ApiError';

import { tryParseJson } from './utils/object';

const debug = Debug('songwhip/api/fetch');
const emitter = mitt();

export interface FetchJsonOptions
  extends Pick<RequestInit, 'mode' | 'credentials'> {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  body?: any;
  headers?: Record<string, string>;
  showToastOnError?: boolean | ((error: any) => boolean);
  keepalive?: boolean;

  /**
   * Allows you to override the default "fetch" api implementation.
   * Can be useful for service side mocking using "nock" and using "node-fetch" for this value.
   * Defaults to browser or node fetch api depending on the context.
   */
  fetchFn?: typeof fetch;
}

const fetchJson = async <Payload extends object = any>(
  url: string,
  {
    body,
    showToastOnError = true,
    fetchFn = fetch,
    ...restOptions
  }: FetchJsonOptions = {}
) => {
  let res: Response;

  const fetchOptions: RequestInit = removeUndefinedKeys({
    method: 'GET',
    body: body && JSON.stringify(body),
    ...restOptions,

    headers: {
      'content-type': 'application/json',
      ...restOptions.headers,
    },
  });

  try {
    debug('fetch', url, fetchOptions);
    res = await fetchFn(url, fetchOptions);
  } catch (error) {
    debug('fetch error', error);

    // This error is throw due to network issues, either
    // DNS lookup or offline. Unsure how we can tell between
    // the types of error here, needs more research.
    const apiError = new ApiError({
      status: 0,
      message: error.message,
      code: error.code,
      method: fetchOptions.method as any,
      showToast:
        typeof showToastOnError === 'function'
          ? showToastOnError(error)
          : showToastOnError,
      url,
    });

    emitter.emit('error', apiError);

    throw apiError;
  }

  const { status, headers } = res;
  const text = await res.text();
  const json = tryParseJson<Payload>(text);

  // the json parsed successfully, but the
  // status code may not be success 200
  if (!res.ok) {
    const parsedError = parseError({ text, json });

    const apiError = new ApiError({
      status,
      method: fetchOptions.method as any,
      url,

      code: parsedError?.code,
      message: parsedError?.message ?? res.statusText,
      data: parsedError?.data,

      showToast:
        typeof showToastOnError === 'function'
          ? showToastOnError(parsedError)
          : showToastOnError,
    });

    emitter.emit('error', apiError);

    throw apiError;
  }

  // if 200 but not json, something is wrong
  if (typeof json !== 'object') {
    throw new ApiError({
      url,
      status,
      method: fetchOptions.method as any,
      message: `Error parsing response: ${text}`,
      showToast:
        typeof showToastOnError === 'function'
          ? showToastOnError(null)
          : showToastOnError,
    });
  }

  return {
    status,
    headers,
    json,
    text,
  };
};

const parseError = ({ json, text }: { text: string; json: unknown }) => {
  if (json && typeof json === 'object' && 'error' in json) {
    const error = json.error as unknown;

    return {
      code: getIn(error, 'code'),
      message: getIn(error, 'message'),
      data: getIn(error, 'data'),
    };
  }

  return {
    message: text,
  };
};

const getIn = (obj: any, key: string) => {
  return key in obj ? obj[key] : undefined;
};

export const onFetchJsonError = (callback: (error: ApiError) => void) => {
  emitter.on('error', callback);

  // return function to unlisten
  return () => {
    emitter.off('error', callback);
  };
};

export default fetchJson;
