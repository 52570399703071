export const forEachValue = <T>(
  obj: T | undefined,
  fn: (item: T[keyof T], key: string, index: number) => void
) => {
  obj && Object.keys(obj).forEach((key, index) => fn(obj[key], key, index));
};

export const findValue = <T extends Record<string, unknown>>(
  obj: T | undefined,
  fn: (item: T[keyof T], key?: string) => any
) => {
  if (!obj) return;

  for (const key in obj) {
    const result = fn(obj[key], key);
    if (result !== undefined) return result;
  }
};

export const tryParseJson = <T>(value: string): T | undefined => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return undefined;
  }
};

export const isEmptyObject = (object?: {}) => !Object.keys(object || {}).length;

export const getObjectKeys = <T extends object>(obj: T): (keyof T)[] => {
  return Object.keys(obj) as (keyof T)[];
};

export const getObjectEntries = <T extends object>(
  obj: T
): [keyof T, T[keyof T]][] => {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
};
