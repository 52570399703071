import React, { HTMLAttributes, RefObject } from 'react';

import withSpacing, { WithSpacingProps } from '~/app/lib/hocs/withSpacing';

export interface BoxProps
  extends WithSpacingProps,
    Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'data-testid' | 'onChange'> {
  tag?: string;
  nodeRef?: RefObject<any>;
  testId?: string;
}

const Box = withSpacing<BoxProps>(
  ({ tag = 'div', nodeRef, testId, ...props }) =>
    React.createElement(tag, {
      // this is first to ensure that if `data-testid` prop is passed it'll still work
      'data-testid': testId,

      ...props,

      // last to ensure `ref` can't be overridden
      ref: nodeRef,
    })
);

export default Box;
